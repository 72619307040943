/*
 * @Date: 2023-04-25 18:34:16
 * @LastEditors: simLarry
 * @LastEditTime: 2023-06-11 11:20:11
 * @FilePath: \F1-M1-QRP-CODE\components\style1\socialbtn.js
 */
import React from "react";


class Socialbtn extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			social_list : [
				{
					icon: '/static/images/hayhar/icon-customer.png',
					text: '在线客服',
					href: this.props.liveChatUrl ? this.props.liveChatUrl : '',
				},{
					icon: '/static/images/hayhar/icon-weibo.png',
					text: '微博',
					href: 'http://www.weibo.com/fun88angel?is_hot=1',
				},{
					icon: '/static/images/hayhar/icon-youku.png',
					text: '优酷',
					href: 'http://i.youku.com/Fun88Bliss',
				}
			]
		}
	}

	componentWillReceiveProps(nextProps , prevState){
		if(nextProps.liveChatUrl !== prevState.liveChatUrl){
			this.setState(state => {
				const list = state.social_list.map((item, index) => {
					if (item.text === '在线客服') {
						item.href = nextProps.liveChatUrl
					}
				})
				return {
				  list
				};
			});
        }
        return;
    }
	
	render(){
		let { social_list } = this.state;
		return (
			<>
				{/* social btns */}				
				<div className={'social-web'}>
					{
						social_list.map((item , key) =>{
							return (
								<>
									{item.text === '在线客服' ? 
										<div className={'social-item'} onClick={()=>{global.globalGtag && global.globalGtag('Style1_QRP','Side nav bar' , 'CS_sidenav' ,'event'); window.open(item.href, "Live Chat", "width=600,height=600") }}>
											<img src={item.icon} />
											<span>{item.text}</span>
										</div>
									:
										<a key={key} href={item.href} className={'social-item'} target="_blank">
											<img src={item.icon} />
											<span>{item.text}</span>
										</a>
									}
								</>

							)
						})
					}
				</div>
			</>
		)
	}
}

export default Socialbtn;