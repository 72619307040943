import React from "react";
import { Button, Input } from 'antd';
import Banner from './banner'; // 
import { RegName , RegEmail , RegPassword , RegPhone, RegNameNumber, RegNameLetter } from '$CONFIG/common/reg';
import { ApiPort } from "../../actions/API";
import { fetchRequest } from "../../config/Fatch.config";
import { Cookie , getBrowserVersion, _handleTheMainSiteUrlOfBackendResponse, getApiVersion } from "../../actions/util";
import Toast from '@/Toast';
import {  bannerConfigs } from '../../config/default/banner';
class Central extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			pathname:'',
			contact : [{
				icon: '',
				text: 'Live Chat',
				href: '',
			},{
				icon: '',
				text: 'Line',
				href: '',
			},{
				icon: '',
				text: 'Facebook',
				href: '',
			},{
				icon: '',
				text: 'Youtube',
				href: '',
			}],


			error_name : false ,
			error_pwd  : false,
			error_email:false,
			error_phone:false,


			EMAIL : '',
			USERNAME : '',
			PHONE : '',
			PWD : '',
			currentUsername: '',
			currentPwd: '',
			currentEmail: '',
			currentPhone: ''
		};
		this.Register = this.Register.bind(this);
		this.emailBox = React.createRef();
		this.phoneBox = React.createRef();
		this.usernameBox = React.createRef();
		this.passwordBox = React.createRef();
	}
	
	componentDidMount(){
		
	}



	/**
	 * 改变参数值
	 * @param { String } k 变量
	 * @param { String | Number | any } v 参数 
	 */
	 setStates(k, v) {
		this.setState({
		  [k]: v
		});
	};


	/**
	 * onChange input 
	 * @param {*} e 
	 * @param {*} type 
	 * @returns 
	 */
	 UserInput(e , type){
		let val = e.target.value || '';
		this.setStates(type,val);
		this.regInput(e , type);
		return ;
		// globalGtag(`Search_withdrawalrecord_Carddetail`);
		
	}


	/**
	 * 注册
	 */
	 Register(){
		let { PHONE  , EMAIL , PWD ,USERNAME} = this.state;
		
		if(PHONE === '' && EMAIL === '' && PWD === '' && USERNAME === '') {
			this.setState({
				error_phone : true,
				error_email : true,
				error_name  : true,
				error_pwd   : true,
			})
			return;
		}
		if(!this.state.error_phone && !this.state.error_email && !this.state.error_name && !this.state.error_pwd){
			try {
				let params = {
					Referrer : Cookie.GetCookieKeyValue('Referer') || global.location.origin,
					currentDomain : global.location.origin,
					email: EMAIL,
					hidBlackbox : (global.ioGetBlackbox && global.ioGetBlackbox().blackbox) || '',
					hidE2 : (global.E2GetBlackbox && global.E2GetBlackbox().blackbox) || '',
					language: 'zh-cn',
					media:Cookie.GetCookieKeyValue('Media') || null,
					password : PWD,
					phoneNumber : PHONE,
					affiliateCode : Cookie.GetCookieKeyValue('CO_affiliate') || '',
					referralCode : Cookie.GetCookieKeyValue('CO_affiliate') || '',
					regWebsite : '1',
					username : USERNAME , 
					websiteId : getBrowserVersion(),
				}
				
				// piwik 追踪
				global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Submit_registration' ,'event');

				Toast.loading()
				fetchRequest(ApiPort.Register,'POST' , params , getApiVersion()).then(res =>{
					if(res){
						Toast.hide();
						if(res.isSuccess){
							global.globalCTag && global.globalCTag('api_return_complete','success'); 
							global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Submit_registration_api_return' ,'event', 'custom');
							_handleTheMainSiteUrlOfBackendResponse(`${res.url}&token=${res.token}`)
						}else{
							global.globalCTag && global.globalCTag('api_return_complete','fail'); 
							global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Submit_registration_api_return' ,'event', 'custom');
							this.props.openModal(res.message);
						}
					}
				}).catch(error =>{
					console.log('服务器错误...');
				})
			} catch (error) {
				console.log('服务器错误...',error);
			}
		}else{
			
		}
		
	}

	/**
	 * input Blur
	 * @param {*} e 
	 * @param {*} type 
	 */
	 inputOnBlur(e,type){
		this.regInput(e , type);
	}


	
	/**
	 * 验证Input 方法
	 * @param {*} e 
	 * @param {*} type 
	 * @returns 
	 */
	 regInput(e ,type){
		let val = e.target.value || '';
		let that = this;
		let inputMap = {
			'USERNAME' : (val)=>{
				document.addEventListener('click', this.handleUsernameClick);
				if(!RegName.test(val)){
					that.setState({
						error_name : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('name-hit-error')[0].innerHTML = '登入昵称不可为空。';
							
						}else{
							document.getElementsByClassName('name-hit-error')[0].innerHTML = '登入昵称必须由 6 － 14 位长度的字母或数字组成，中间不得有读音符号、空格或下划线。';
						}
						
					});	
				} else{
					that.setState({
						error_name :  false
					});
					document.getElementsByClassName('name-hit-error')[0].innerHTML = '';
				}
				return ;
			},
			'PWD' : (val) =>{
				document.addEventListener('click', this.handlePasswordClick);
				if(!RegPassword.test(val)){
					that.setState({
						error_pwd : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = '密码不可为空。';
						}else{
							document.getElementsByClassName('pass-hit-error')[0].innerHTML = '密码必须包含6-20个字符，字符只限于使用字母和数字。（可以包含 ^#$@ 中的特殊字符）。';
						}
					})
					
				}else{
					that.setState({
						error_pwd :  false
					})
					document.getElementsByClassName('pass-hit-error')[0].innerHTML = '';
				}
				return ;
			},
			'EMAIL' : (val) =>{
				document.addEventListener('click', this.handleEmailClick);
				if(!RegEmail.test(val)){
					that.setState({
						error_email : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('email-hit-error')[0].innerHTML = '电子邮箱不可为空。';
						}else{
							document.getElementsByClassName('email-hit-error')[0].innerHTML = '电子邮箱格式无效';
						}
					})
				}else{
					that.setState({
						error_email :  false
					})
					document.getElementsByClassName('email-hit-error')[0].innerHTML = '';
				}
				return;
				
			},
			'PHONE' : (val) =>{
				document.addEventListener('click', this.handlePhoneClick);
				if(!RegPhone.test(val)){
					that.setState({
						error_phone : true
					},()=>{
						if(val === ''){
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = '联系电话不可为空。';
						}else{
							document.getElementsByClassName('phone-hit-error')[0].innerHTML = '联系电话格式无效，请您核查。';
						}
					})
				}else{
					that.setState({
						error_phone :  false
					})
					document.getElementsByClassName('phone-hit-error')[0].innerHTML = '';
				}
				return
			}
		}
		inputMap[type](val);
		return ;
	}

	handleEmailClick = (event) => {
		if (this.emailBox && this.emailBox.current && !this.emailBox.current.contains(event.target) && !this.state.error_email && (this.state.currentEmail != this.state.EMAIL)) {
			this.setState({
				currentEmail :  this.state.EMAIL,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Email_input' ,'input');
			return document.removeEventListener('click', this.handleEmailClick)
		}
	}

	handlePhoneClick = (event) => {
		if (this.phoneBox && this.phoneBox.current && !this.phoneBox.current.contains(event.target) && !this.state.error_phone && (this.state.currentPhone != this.state.PHONE)) {
			this.setState({
				currentPhone :  this.state.PHONE,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Phone_input' ,'input');
			return document.removeEventListener('click', this.handlePhoneClick)
		}
	}

	handleUsernameClick = (event) => {
		if (this.usernameBox && this.usernameBox.current && !this.usernameBox.current.contains(event.target) && !this.state.error_name && (this.state.currentUsername != this.state.USERNAME)) {
			this.setState({
				currentUsername :  this.state.USERNAME,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Username_input' ,'input');
			return document.removeEventListener('click', this.handleUsernameClick)
		}
	}

	handlePasswordClick = (event) => {
		if (this.passwordBox && this.passwordBox.current && !this.passwordBox.current.contains(event.target) && !this.state.error_pwd && (this.state.currentPwd != this.state.PWD)) {
			this.setState({
				currentPwd :  this.state.PWD,
			});
			global.globalGtag && global.globalGtag('Style1_QRP','Registration form' , 'Password_input' ,'input');
			return document.removeEventListener('click', this.handlePasswordClick)
		}
	}
	
	render(){
		let {error_name ,error_pwd , error_email , error_phone , PWD ,USERNAME , EMAIL , PHONE  } = this.state;
		let { bannerList , pagename } = this.props;
		return (
			<>
				<Banner bannerList={bannerList.length === 0 && pagename !== '' ? bannerConfigs[pagename] : bannerList}></Banner>

				<div className={`${this.state.pathname} central-web web`}>
					<div className="central-box web">
						<div className={'heading'}>体育｜真人娱乐｜老虎机｜棋牌｜彩票｜捕鱼</div>
						<p className={'tips'}>欢乐迎新礼，独家首存送800元！</p>
						<div className={'form-modal'}>
							<div className="namePass">
								{/* 用户名 */}
								<div style={{width: '49%'}} ref={this.usernameBox} className="usernameBox">
									<Input style={{width: '100%'}} onBlur={(e)=>{this.inputOnBlur(e ,'USERNAME')}} type={'name'} onChange={(e)=>{this.UserInput(e ,'USERNAME')}} maxLength={14} placeholder="登入昵称" value={USERNAME}></Input>
								</div>
								{/* 密码 */}
								<div style={{width: '49%'}} ref={this.passwordBox} className="passwordBox">
									<Input style={{width: '100%'}} type={'password'} onBlur={(e)=>{this.inputOnBlur(e ,'PWD')}} onChange={(e)=>{this.UserInput(e ,'PWD')}} maxLength={20} name="password" placeholder="输入密码" value={PWD}></Input>
								</div>
							</div>

							<div className="error-hit">
								{/* 用户名错误显示 */}
								<p className="name-hit-error"></p>
								<p className="pass-hit-error"></p>
							</div>
							

							{/* 电子邮件 */}
							<div className="email-input" ref={this.emailBox}>
								<Input name="email" onBlur={(e)=>{this.inputOnBlur(e ,'EMAIL')}} maxLength={100} placeholder="邮箱" onChange={(e)=>{this.UserInput(e ,'EMAIL')}} value={EMAIL}></Input>
							</div>

							<div className="error-hit">
								<p className="email-hit-error"></p>
							</div>


							{ /* 电话号码 */ }
							<div className="phone-number" ref={this.phoneBox}>
								<Input className="phone" name="phone" disabled type="tel" value={'+86'}></Input>
								<Input className="phoneNumber" onBlur={(e)=>{this.inputOnBlur(e ,'PHONE')}} maxLength={11} name="phoneNumber" id="inputPhone" placeholder="电话号码" onChange={(e)=>{this.UserInput(e ,'PHONE')}} value={PHONE}></Input>
							</div>
							<div className="error-hit">
								<p className="phone-hit-error"></p>
							</div>
							
						</div>
						<Button className="submit"  onClick={this.Register}>马上加入</Button>
					</div>
				</div>
				
				
				{/* mobile and ipad */}
				<div className={`mobile mobile-${this.state.pathname}`}>
					<div className="mobile-central">
						<div className='text-top' style={{display:"none"}}>
							<h2>เว็บบอลอันดับ 1 มั่นคง ปลอดภัย </h2>
							<p>โบนัสสูง 150% ฟรีเสื้อบอลโลก</p>
						</div>
					</div>
					<div className="central-box">
						<div className={'heading'}>体育｜真人娱乐｜老虎机｜棋牌｜彩票｜捕鱼</div>
						<p className={'tips'}>欢乐迎新礼，独家首存送800元！</p>
					</div>
					<div className={'form-modal'} id={'mobile-heading'}>
						<div className="namePass">
							{/* 用户名 */}
							<Input onBlur={(e)=>{this.inputOnBlur(e ,'USERNAME')}} onChange={(e)=>{this.UserInput(e ,'USERNAME')}} maxLength={14} placeholder="登入昵称" value={USERNAME} ></Input>
							{/* 密码 */}
							<Input type={'password'} maxLength={20}  onBlur={(e)=>{this.inputOnBlur(e ,'PWD')}} onChange={(e)=>{this.UserInput(e ,'PWD')}} name="password" placeholder="输入密码" value={PWD}></Input>
						</div>

						<div className="error-hit">
							{/* 用户名错误显示 */}							
							{error_name && !USERNAME ? <p className="name-hit-error">登入昵称不可为空。</p> : error_name && USERNAME ? <p>登入昵称必须由 6 － 14 位长度的字母或数字组成，中间不得有读音符号、空格或下划线。</p> : <p></p>}
							{error_pwd && !PWD ? <p className="pass-hit-error">密码不可为空。</p> : error_pwd && PWD ? <p>密码必须包含6-20个字符，字符只限于使用字母和数字。</p> : <p></p>}
						</div>
						{/* 电子邮件 */}
						<div className="email-input">
							<Input onBlur={(e)=>{this.inputOnBlur(e ,'EMAIL')}}  onChange={(e)=>{this.UserInput(e ,'EMAIL')}} value={EMAIL} name="email" maxLength={100} placeholder="电子邮件"></Input>
						</div>

						<div className="error-hit">
							{error_email && !EMAIL? <p className="email-hit-error">电子邮箱不可为空。</p> : error_email && EMAIL ?<p>电子邮箱格式无效</p> : <p></p>}
						</div>
						{ /* 电话号码 */ }
						<div className="phone-number">
							<Input className="phone" name="phone" disabled type="tel" value={'+86'}></Input>
							<Input className="phoneNumber" maxLength={11} name="phoneNumber" id="inputPhone" placeholder="电话号码" onBlur={(e)=>{this.inputOnBlur(e ,'PHONE')}} onChange={(e)=>{this.UserInput(e ,'PHONE')}} value={PHONE}></Input>
						</div>

						<div className="error-hit">
							{error_phone && !PHONE ? <p className="phone-hit-error">联系电话不可为空。</p> : error_phone && PHONE ? <p>联系电话格式无效，请您核查。</p> : <p></p>}
						</div>
					</div>
					<Button className="submit"  onClick={this.Register}>马上加入</Button>
				</div>
				
				
			</>
		)
	}
}


export default Central;
