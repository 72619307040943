import React from "react";
import { Input , Button } from 'antd';
import {
    _handleTheMainSiteUrlOfBackendResponse,
    getFixedMainSiteUrl,
    getAffFromCookie,
	getApiVersion,
  } from "../../actions/util";
import { fetchRequest } from "../../config/Fatch.config";
import { ApiPort } from "../../actions/API";
import Toast from '@/Toast';
import Logo from "../Logo";
import Router from 'next/router';

class Header extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			password : '',
			username : '',
		};

		this.loginMobile = this.loginMobile.bind(this);
		this.login = this.login.bind(this);
	}

	loginMobile(){
		const aff = getAffFromCookie();
		let { mainSiteUrl } = this.props;
		console.log(mainSiteUrl);
		_handleTheMainSiteUrlOfBackendResponse(`${mainSiteUrl}${aff ? `?aff=${aff}` : ""}`);
	}


	join(){

		global.globalGtag && global.globalGtag(`Style1_QRP`,'Registration form','Register_topnav','event')
		const dom = document.getElementById('mobile-heading');
		dom.scrollIntoView({behavior : 'smooth'});
	}

	/**
	 * 登录
	 * @returns
	 */
	login(){
		let { password , username } = this.state;
		debugger
		if(!password && !username) return ;
		let params = {
			currentDomain : global.location.origin || '',
			hidE2 :(global.E2GetBlackbox && global.E2GetBlackbox().blackbox )|| '',
			language : 'zh-cn',
			password : password ,
			username : username
		}
		global.globalGtag(`Style1_QRP`,'Login','Login_topnav','event')
		Toast.loading()
		try {
			fetchRequest(ApiPort.Login , 'POST' , params , getApiVersion()).then(res =>{
				try {
					Toast.hide();
					if(res){
						if(res.isSuccess){
							global.globalCTag && global.globalCTag('api_return_complete','success');
							global.globalGtag && global.globalGtag('Style1_QRP','Login' , 'Login_topnav_api_return' ,'event', 'custom');
							_handleTheMainSiteUrlOfBackendResponse(`${res.url}&token=${res.token}`);
						}else{
							global.globalCTag && global.globalCTag('api_return_complete','fail');
							global.globalGtag && global.globalGtag('Style1_QRP','Login' , 'Login_topnav_api_return' ,'event', 'custom');
							this.props.openModal(res.message);
							// Toast.fail(res.message);
						}
					}
				} catch (error) {
					console.log('====================================');
					console.log('服务器错误');
					console.log('====================================');
				}
			})
		} catch (error) {
			console.log('====================================');
			console.log('服务器错误');
			console.log('====================================');
		}

	}

	render(){
		let { password , username } = this.state;
		let { mainSiteUrl } = this.props;
		const aff = getAffFromCookie();

		return (
			<>
				<div className={'top web header-in-style1'}>
					<Logo mainSiteUrl={mainSiteUrl}></Logo>
					<div className={'web-input-box'}>
						{/* 用户名 */}
						<Input maxLength={20} name="username" type={'text'} placeholder="用户名" className={'web-input'} value={username} onChange={(e)=>{this.setState({username : e.target.value})}}></Input>
						{/* 密码 */}
						<Input className={'web-input'} name="password" type={'password'} placeholder="密码" value={password} onChange={(e)=>{this.setState({password : e.target.value})}}></Input>
						{/* 忘记密码了吗 */}
						<div className={'web-label' + (mainSiteUrl ? '' : ' web-label-forgotpwd-hide')} onClick={()=> window.location.replace(getFixedMainSiteUrl(mainSiteUrl)+`?openForgotPassword=1${aff ? `&aff=${aff}` : ""}`)}>忘记？</div>
						{/* 登录 */}
						<Button className={'web-login'}  onClick={this.login}>登录</Button>
						<img src="/static/images/hayhar/flag-china.png" className={'country-icon'} />
					</div>
				</div>

				{/* mobile and ipad */}
				<div className="top mobile">
					<Logo mainSiteUrl={mainSiteUrl}></Logo>
					<div className={'web-input-box'}>
						{/* 登录 */}
						<Button className="login" onClick={this.loginMobile}>登录</Button>
						{/* 现在加入 */}
						<Button className="register" onClick={this.join}>现在就加入</Button>
					</div>
				</div>
			</>
		)
	}
}


export default Header;
